// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyA4hwKuADgNhonjRu_MfcRcNMclSg063nk",
    authDomain: "hireme-prod-eae66.firebaseapp.com",
    projectId: "hireme-prod-eae66",
    storageBucket: "hireme-prod-eae66.appspot.com",
    messagingSenderId: "676485583266",
    appId: "1:676485583266:web:b54eed192305d1358c22fb",
    measurementId: "G-XF2NC05FPC"
  },
  emailUrl: 'https://app.hireme.cloud',
  adminRight: ['Admin', 'HRManager', 'HRExecutive'],
  serviceUrl: 'https://us-central1-hireme-prod-eae66.cloudfunctions.net/',
  paymentUrl: 'https://qsnsvijqy6.execute-api.us-east-1.amazonaws.com/prod/api/v1',
  msApi: 'https://graph.microsoft.com/v1.0/drive/root:',
  oneDriveFolder: 'HireMe',
  senderMail: 'no-reply@hireme.cloud',
  mainCollection: 'HiremeApp',
  tenantCollection: 'HiremeTenants',
  configCollection: 'HiremeConfig',
  rolesAllowedToHRManager: ['HRManager', 'HRExecutive', 'Interviewer'],
  rolesAllowedToHRExecutive: ['HRExecutive', 'Interviewer'],
  interviewersRole: ['Interviewer'],
  razorPayId: 'rzp_live_0igAdJp8LR74TL',
  defaultPlan: 'plan_MWBSaxA4Ne7uA13zqjxS',
  defaultPlanName: "Hireme Basic Monthly",
  salesforceUrl: 'https://iejigzq15h.execute-api.us-east-1.amazonaws.com/dev',
  msLoginClientId: 'f303b6c3-0f4b-43e3-9017-f8f0552fe019',
  msLoginRedirectUrl: 'https://app.hireme.cloud',
  parserUrl: 'https://us-central1-hireme-prod-eae66.cloudfunctions.net',
  parseToken: '89bdec5cc7b3e9e73f9b5475124b194e'
};

/*

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
