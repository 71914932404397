import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-show-ratings',
  templateUrl: './show-ratings.component.html',
  styleUrls: ['./show-ratings.component.css']
})
export class ShowRatingsComponent implements OnInit {

  @Input() dataRatings: string;
  constructor() { }

  ngOnInit(): void {
  }


  showRating(rating) {
    if (rating != undefined) {
      let total = rating.reduce((a, b) => a + b.avg_rating, 0);
      return this.styleRating(total / rating.length)
    } else {
      return null;
    }
  }

  styleRating(rating) {
    if (rating >= 0.1 && rating <= 0.5) {
      return 0.5;
    } else if (rating >= 1 && rating <= 1.4) {
      return 1.0

    } else if (rating >= 1.5 && rating <= 1.9) {
      return 1.5

    } else if (rating >= 2 && rating <= 2.4) {
      return 2.0

    } else if (rating >= 2.5 && rating <= 2.9) {
      return 2.5

    } else if (rating >= 3 && rating <= 3.4) {
      return 3.0
    }
    else if (rating >= 3.5 && rating <= 3.9) {
      return 3.5
    }
    else if (rating >= 4 && rating <= 4.4) {
      return 4.0
    }
    else if (rating >= 4.5 && rating <= 4.9) {
      return 4.5
    }
    else if (rating == 5) {
      return 5.0
    } else {
      return 0
    }
  }
}
