import { Injectable } from '@angular/core';
import {
  Router, CanActivate, ActivatedRouteSnapshot
} from '@angular/router';

@Injectable()
export class RoleService implements CanActivate {
  permission: any;
  constructor(public router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const currentRole = JSON.parse(sessionStorage.getItem('userInfo'));
    if (currentRole != null) {
      const allowedRole = currentRole[0].role;
      const allowedPath = route.data.path;

      const permissionCheck = await this.menuAllowed(allowedRole);
      const allowed = permissionCheck.allowedMenu;
      if (allowed.includes(allowedPath)) {
        return true;
      } else {
        return false;
      }
    } else {
      this.router.navigate(['signin']);
    }
  }


  async menuAllowed(menu): Promise<any> {
    switch (menu) {
      case 'Admin':
        return { allowedMenu: ['dashboard', 'jobs', 'candidate', 'interviews', 'clients', 'contacts', 'contracts', 'settings', 'skills', 'user', 'pipeline'] };

      case 'HRManager':
        return { allowedMenu: ['dashboard', 'jobs', 'candidate', 'interviews', 'clients', 'contacts', 'contracts', 'settings', 'skills', 'user', 'pipeline'] };

      case 'HRExecutive':
        return { allowedMenu: ['dashboard', 'jobs', 'candidate', 'interviews', 'clients', 'contacts', 'contracts', 'settings', 'skills', 'user', 'pipeline'] };

      case 'Interviewer':
        return { allowedMenu: ['interviews', 'settings'] };
        
      case 'Candidate':
        return { allowedMenu: ['employee', 'applies', 'my-interviews', 'job-openings','candidate-settings','my-profile'] };

    }
  }
}
