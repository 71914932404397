import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private _http: HttpClient
  ) { }
  private _refreshImageNeeded$ = new Subject<void>();
  private _refreshLogoNeeded$ = new Subject<void>();
  serviceUrl = environment.paymentUrl;

  get refreshImageNeeded$() {
    return this._refreshImageNeeded$;
  }

  get refreshLogoNeeded$() {
    return this._refreshLogoNeeded$;
  }

  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  async getProfileById(collectionName: any, id) {
    return await this.firestore
      .collection(collectionName)
      .doc(id)
      .snapshotChanges();
  }

  async updateProfile(collectionName: any, profileId, data) {
    return await this.firestore
      .collection(collectionName)
      .doc(profileId)
      .update(data);
  }

  async addProfile(collectionName: any, data) {
    return await this.firestore.collection(collectionName).add(data);
  }

  async addData(collectionName: any, userId, data) {
    return await this.firestore
      .collection(collectionName)
      .doc(userId)
      .set(data);
  }
  
  async addPlan(collectionName: any, docId, data) {
    return await this.firestore
      .collection(collectionName)
      .doc(docId)
      .set(data);
  }

  async updatePlan(collectionName: any, docId, data) {
    return await this.firestore
      .collection(collectionName)
      .doc(docId)
      .update(data);
  }

  async addTransaction(collectionName: any, data) {
    return await this.firestore.collection(collectionName).add(data);
  }

  async getCompanyData(collectionName: any, field, value) {
    return await this.firestore
      .collection(collectionName, (ref) => ref.where(field, '==', value))
      .valueChanges({ idField: 'id' })
      .pipe(first())
      .toPromise();
  }

  async getAllData(collectionName) {
    return await this.firestore.collection(collectionName).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  // update
  async uploadImage(file, userid, tenantPath) {
    var n = Date.now();
    const filePath = `${tenantPath}/images/${n}`;
    const fileRef = this.storage.ref(filePath);
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    const uploadTaskSnapshot = await fileRef.put(file);
    const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

    user[0]['profile_pic'] = downloadURL;
    sessionStorage.setItem('userInfo', JSON.stringify(user));
    this.updateProfile(`${tenantPath}/Users`, userid, user[0]);
    this._refreshImageNeeded$.next(downloadURL);
    return downloadURL;
  }

  async uploadLogo(file, userid, tenantPath, data) {
    var n = Date.now();
    const filePath = `${tenantPath}/images/${n}`;
    const fileRef = this.storage.ref(filePath);

    const uploadTaskSnapshot = await fileRef.put(file);
    const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

    data.companyLogo = downloadURL;
    this.updateProfile(`HiremeTenants`, userid, data);
    this._refreshLogoNeeded$.next(downloadURL);
    return downloadURL;
  }

  async myPlans() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return await this._http
      .get(`${this.serviceUrl}/plans`, httpOptions)
      .toPromise();
  }

  async createCustomer(data) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };

      return await this._http
        .post(`${this.serviceUrl}/customers`, data, httpOptions)
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  async createOrder(data) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };

      return await this._http
        .post(`${this.serviceUrl}/orders`, data, httpOptions)
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  async createSubscription(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return await this._http
      .post(`${this.serviceUrl}/subscription`, data, httpOptions)
      .toPromise();
  }

  async updateSubscription(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return await this._http
      .post(`${this.serviceUrl}/updateSubscription`, data, httpOptions)
      .toPromise();
  }

  async cancelSubscription(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return await this._http
      .post(`${this.serviceUrl}/subscription/cancel`, data, httpOptions)
      .toPromise();
  }

  async createAssets(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return await this._http
      .post(`${environment.salesforceUrl}/createAsset`, data, httpOptions)
      .toPromise();
  }

  async downloadInvoice(invoiceid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return await this._http
      .get(`${this.serviceUrl}/invoice?id=${invoiceid}`, httpOptions)
      .toPromise();
  }
}
