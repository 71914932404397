import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { RoleService } from './auth/role.service';
import { WalkthroughComponent } from './shared/walkthrough/walkthrough.component';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    data: {
      title: 'SignIn - HireME'
    }
  },
  {
    path: 'jobs', loadChildren: () => import('./job-opening/job-opening.module').then(m => m.JobOpeningModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'jobs',
      title: 'Jobs - HireME'
    }
  },

  {
    path: 'contracts', loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'contracts',
      title: 'Contracts - HireME'
    }
  },

  {
    path: 'pipeline', loadChildren: () => import('./pipeline/pipeline.module').then(m => m.PipelineModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'pipeline',
      title: 'Pipeline - HireME'
    }
  },

  {
    path: 'candidate', loadChildren: () => import('./candidates/candidates.module').then(m => m.CandidatesModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'candidate',
      title: 'Candidate - HireME'
    }
  },
  {
    path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'clients',
      title: 'Clients - HireME'
    }
  },
  {
    path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'contacts',
      title: 'Contacts - HireME'
    }
  },
  {
    path: 'skills', loadChildren: () => import('./skills/skills.module').then(m => m.SkillsModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'skills',
      title: 'Skills - HireME'
    }
  },
  {
    path: 'interviews', loadChildren: () => import('./interviews/interviews.module').then(m => m.InterviewsModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'interviews',
      title: 'Interviews - HireME'
    }
  },
  {
    path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'user',
      title: 'User - HireME'
    }
  },
  {
    path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'dashboard',
      title: 'Dashboard - HireME'
    },

  },
  {
    path: 'settings', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'settings',
      title: 'Settings - HireME'
    }
  },
  {
    path: 'walkthrough', component: WalkthroughComponent
  },
  {
    path: 'employee', loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [RoleService],
    data: {
      allowedRole: JSON.parse(sessionStorage.getItem('userInfo')),
      path: 'employee',
      title: 'Candidate - HireME'
    }
  },
  { path: '**', pathMatch: 'full', redirectTo: '/' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
